<template>
  <div class="card card-custom">
    <div class="card-header border-0 pt-6 pb-0">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t("Physical person invite list") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <input v-model="searchFilter" class="min-w-300px form-control form-control-solid mr-4" type="text"
          :placeholder="$t('Search')">
        <euro-select v-model="itemFilter" class="min-w-200px mr-4 mb-0"
          :multiselect-props="multiselectProps"></euro-select>
        <router-link :to="{ name: 'focus-users.user-create' }" class="btn btn-light-primary font-weight-bolder">
          <div class="d-flex">
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/Communication/Add-user.svg" />
            </span>
            {{ $t("Create") }}
          </div>
        </router-link>
      </div>
    </div>
    <div class="card-body detail">
      <datatable ref="datatableRef" :table-props="tableProps" :options.sync="tableOptions"
        :per-page-options="perPageOptions" search :total="total">
        <!-- CELLS -->

        <template #[`cell.name`]="{ item }">
          <div class="d-flex align-items-center">
            <Avatar :avatar-image="item.avatar" :avatar-text="`${item?.name?.[0]}${item?.surname?.[0]}`" />
            <div class="ml-3">
              <div class="font-weight-bold" :style="{ wordBreak: 'break-word' }">
                {{ `${item?.name} ${item?.surname}` }}
              </div>
              <div v-if="item.is_temporary_employee" class="text-muted">
                {{ $t("Temporary worker") }}
              </div>
              <div v-else-if="item.is_employee" class="text-muted">
                {{ $t("Employee") }}
              </div>
              <div v-else class="text-muted">
                {{ $t("Standard person") }}
              </div>
            </div>
          </div>
        </template>

        <template #[`cell.main_address`]="{ item }">
          <div v-if="item.physicalpersonaddress_set.length">
            <div class="font-weight-normal">{{ getMainAddress(item) }}</div>
            <div class="text-muted">{{ getMainAddressDescription(item) }}</div>
          </div>
        </template>

        <template #[`cell.tel_contact`]="{ item }">
          <div v-if="item.telcontact_set.length">
            <div class="font-weight-normal">{{ getMainTel(item) }}</div>
            <div class="text-muted">{{ getMainTelKind(item) }}</div>
          </div>
        </template>

        <template #[`cell.extraemail_set`]="{ item }">
          <div v-if="getMainEmail(item)" class="max-width-column">
            <div class="font-weight-normal">{{ getMainEmail(item) }}</div>
          </div>
          <div v-else-if="item.user_email != null" class="max-width-column font-weight-normal">{{ item.user_email }}</div>
          <div v-else class="max-width-column font-weight-normal">
            <button class="btn btn-light-warning btn-sm  font-weight-bolder" @click="goToPhysicalPersonContacts(item)">
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/Communication/Mail-error.svg" />
              </span>
              {{ $t("Add Email") }}
            </button>
          </div>
        </template>

        <template #[`cell.actions`]="{ item }">
          <div class="d-flex justify-content-start">
            <b-button v-b-tooltip :title="$t('Invite user')" class="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
              @click="submitInviteUser(item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/media/svg/icons/Communication/Send.svg" />
              </span>
            </b-button>
          </div>
        </template>

      </datatable>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import InvitationService from "@/core/services/invitation/invitation.service";
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
import Datatable from "@/view/components/tables/Datatable.vue";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import {
  backendErrorSwal,
  validationErrorSwal,
  quickInviteSendSwal,
  successSwal
} from "@/core/helpers/swal";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"

export default {
  components: {
    Datatable,
  },
  data() {
    return {
      DateService,
      isLoading: false,
      itemFilter: "ALL",
      // To edit
      selectOptions: [
        { value: "ALL", text: this.$t("All") },
        { value: "EMPLOYEE", text: this.$t("Employee") },
      ],
      fields: [
        {
          label: this.$t("Name"),
          key: "name",
          thClass: ["d-flex", "justify-content-left"],
          sortable: true,
        },
        { label: this.$t("Mail"), key: "extraemail_set", class: ["font-weight-bolder"] },
        { label: this.$t("Telephone"), key: "tel_contact", class: ["font-weight-bolder"] },
        { label: this.$t("Actions"), key: "actions", class: "align-end col-actions" },
      ],
      items: [],
      search: "",
      searchFilter: "",
      filter: "",
      total: 0,
      TELEPHONE_KINDS: [],
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      userEmail: "",
      isSubmitting: false,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat"]),
    tableProps() {
      return {
        busy: this.isLoading,
        fields: this.fields,
        filter: this.searchFilter,
        "no-sort-reset": true,
        responsive: true,
        "tbody-tr-class": this.trClass,
        items: this.itemProvider,
      };
    },
    multiselectProps() {
      return {
        options: this.selectOptions,
        searchable: false,
        showLabels: false,
        "allow-empty": false,
      }
    },
    /**
     Il seguente filtro viene utilizzato nella chiamata backend
     true  = persone fisiche di tipo employee
     false = persone fisiche di tipo non employee
     null  = tutte le persone fisiche
     */
    employeeFilter() {
      switch (this.itemFilter) {
        case "EMPLOYEE": {
          return true
        };
        default: {
          return null
        }
      }
    }
  },

  watch: {
    itemFilter() {
      this.refresh();
    }
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Physical person invite") },
      { title: this.$t("Manage") },
    ]);
  },

  methods: {
    itemProvider(ctx, callback) {
      PhysicalPersonService.getAll({
        perPage: ctx.perPage,
        page: ctx.currentPage,
        sortBy: ctx.sortBy || "created",
        sortDesc: ctx.sortDesc,
        search: ctx.filter,
        employeeFilter: this.employeeFilter,
        is_user: false
      })
        .then(res => {
          console.log(res)
          this.total = res.count;
          callback(res.results);
        })
        .catch((err) => {
          backendErrorSwal(err, "Failed to load items");
          callback([]);
        });

      return null;
    },
    refresh() {
      this.$refs.datatableRef.refresh();
    },

    getMainAddress(item) {
      const addresses = item.physicalpersonaddress_set;
      if (!addresses.length) {
        return "";
      }

      if (addresses.length === 1) {
        return addresses[0].address?.formatted ?? addresses[0].address.raw;
      }

      const address = addresses.find((el) => el.main);
      if (!address) {
        return "";
      }

      return address.address?.formatted ?? address.address.raw;
    },

    getMainAddressDescription(item) {
      const addresses = item.physicalpersonaddress_set;
      if (!addresses.length) {
        return "";
      }
      if (addresses.length === 1) {
        return addresses[0].description;
      }

      const address = addresses.find((el) => el.main);
      if (!address) {
        return "";
      }
      return address.description;
    },

    getMainTel(item) {
      const telephones = item.telcontact_set;
      if (!telephones.length) {
        return "";
      }
      const tel = telephones.find((el) => el.main);
      const notMain = telephones["0"];
      if (!tel && !notMain) {
        return "";
      }
      return tel?.number || notMain?.number || "";
    },

    getMainTelKind(item) {
      const telephones = item.telcontact_set;
      if (!telephones.length) {
        return "";
      }
      const tel = telephones.find((el) => el.main);
      const notMain = telephones["0"];
      if (!tel && !notMain) {
        return "";
      }
      return (
        this.TELEPHONE_KINDS.find((el) => el.value == tel?.kind)?.text ||
        this.TELEPHONE_KINDS.find((el) => el.value == notMain?.kind)?.text ||
        ""
      );
    },


    async submitInviteUser(body) {
      let email = body.extraemail_set.filter(el => el.main == true).map(el => el.email)[0]
      if (!email) {
        let message = this.$t("No email found, add an email first to invite the user");
        await validationErrorSwal(null, message);
        return;
      }
      this.isSubmitting = true;
      quickInviteSendSwal(email).then(async (res) => {
        if (res.isConfirmed) {
          try {
            const role = res.value;
            let emailUser = body.user_email ? body.user_email : body.extraemail_set.filter(el => el.main == true).map(el => el.email)[0];
            await InvitationService.createInvitation({ inviteType: 'user', email: emailUser, physicalPerson: body, userID: body.user_id, role: role });
            await successSwal({
              text: this.$t("Invite mail sent"),
              title: this.$t("Success")
            }).then(() => {
              this.$router.push({ name: "focus-users.user-manage" });
            });

          } catch (err) {
            console.error(err);
            let message = '';
            if (err.response?.data) {
              for (let key in err.response.data) {
                if (err.response.data.hasOwnProperty(key)) {
                  message += err.response.data[key][0] + ' ';
                }
              }
            } else {
              message = "Unable to send the invitation";
            }
            await backendErrorSwal(err, message);
          } finally {
            this.isSubmitting = false;
          }

        }
      })
    },
    goToPhysicalPersonContacts(item) {
      this.$router.push({ name: "detail-physical-contacts", params: { userID: item.id } });
    },
    
    getMainEmail(item) {
      const emails = item.extraemail_set;
      if (!emails.length) {
        return "";
      }
      const email = emails.find((el) => el.main);
      return email?.email || "";
    }

  },
};
</script>

<style lang="scss" scoped>
i.arrow {
  font-size: 0.7rem;
}

.per-page-select {
  max-width: 5rem;
}

.max-width-column {
  max-width: 200px;
}
</style>
